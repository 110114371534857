<template>
    <div class="mt-4 px-md-4 configure-app">
        <v-snackbar v-model="copied" :timeout="2000" top color="purple"> Link Copied Successfully. </v-snackbar>
        <span class="fs--14 fw--500">STEP 1</span>
        <p class="fs--13 fw--400 fieldFore--text mt-4">
            Download the <span class="blueWaves--text fw--600">Stick-O-Vision</span> application by clicking the button
            below. If you run a dual computer streaming setup, this would be run on the PC you play games with, not your
            streaming PC.
        </p>

        <v-btn @click="actions.downloadApp()" class="text-capitalize ma-1" color="pink" depressed>
            <v-icon class="mr-1">{{ icons.mac }}</v-icon>
            Download for Mac</v-btn>

        <v-divider class="my-4"></v-divider>

        <span class="fs--14 fw--500">STEP 2</span>

        <p class="fs--13 fw--400 fieldFore--text mt-4">Extract the ZIP files to any location on your computer and run the application file called <span class="blueWaves--text fw--600">Stick-O-Vision</span>.</p>
        <p class="fs--13 fw--400 fieldFore--text">
            Next, copy your personal <span class="blueWaves--text fw--600">Browser Source URL</span> to your clipboard using the <span class="blueWaves--text fw--600">COPY</span> button below:
        </p>
        <v-row no-gutters class="mb-6">
            <v-col cols="8" sm="9" lg="10" xl="10" class="pr-3 source-url">
                <v-text-field disabled background-color="purple" color="fieldFore" dense flat solo hide-details>
                    <template #label>
                        <div class="d-flex">
                            <span :class="blur && 'blury-link'">{{ browserSourceLink }}</span>
                        </div>
                    </template>
                </v-text-field>
                <span v-if="blur" @click="actions.removeBlur" class="unblur-text cursor-pointer white--text fs--12"><v-icon
                        size="18" color="white">{{
                            icons.eye
                        }}</v-icon> Click here to show the
                    URL</span>
            </v-col>
            <v-col cols="4" sm="3" lg="2" xl="2">
                <v-btn depressed color="primary" block @click="actions.copy">Copy</v-btn>
            </v-col>
        </v-row>

        <v-divider class="mb-4"></v-divider>

        <span class="fs--14 fw--500">STEP 3</span>
        <p class="fs--13 fw--400 fieldFore--text mt-4">
            On the application starting screen, paste your <span class="blueWaves--text fw--600">Browser Source URL</span> into the field as shown below.
        </p>

        <div class="text-center mb-6">
            <img src="@/assets/images/stick-o-vision-instructions.png" width="100%" alt="">
        </div>

        <v-divider class="mb-4"></v-divider>

        <span class="fs--14 fw--500">STEP 4</span>
        <p class="fs--13 fw--400 fieldFore--text mt-4">
            By default, the Application Audio is set to <span class="blueWaves--text fw--600">OFF</span> as you should already be hearing the sticker audio from your streaming software such as OBS. If you prefer to run the sticker audio from the application, you can toggle on the “Enable Application Source Audio”, however, you’ll need to make sure to mute any Browser Source layers in OBS to ensure you don’t hear double sounds.
        </p>

        <v-divider class="mb-4"></v-divider>

        <span class="fs--14 fw--500">STEP 5</span>
        <p class="fs--13 fw--400 fieldFore--text mt-4">
            Click <span class="blueWaves--text fw--600">Enable</span> and let the chaos begin!
        </p>
        <p class="fs--13 fw--400 fieldFore--text">
            If at any time you want to toggle the overlay visibility or return to the settings screen, simply use the configured hotkeys while the overlay is running.
        </p>
    </div>
</template>

<script>
import { mdiMicrosoftWindows, mdiApple, mdiEye } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { getEndpointHost } from '@/utils/config';
import { getUserId } from '@/utils/auth';

export default {
    setup() {
        const browserSourceLink = `https://${getEndpointHost()}:8081/stickers/${getUserId()}/mt`;
        const copied = ref(false);
        const blur = ref(true);

        const actions = {
            downloadApp() {
                window.open('https://bit.ly/Stick-O-Vision-MAC', '_blank')
            },
            copy: () => {
                navigator.clipboard.writeText(browserSourceLink).then(() => {
                    copied.value = true;
                    setTimeout(() => {
                        copied.value = false;
                    }, 2000);
                });
            },
            removeBlur: () => {
                blur.value = false;
            },
        }
        return {
            blur,
            browserSourceLink,
            copied,
            actions,
            icons: {
                windows: mdiMicrosoftWindows,
                mac: mdiApple,
                eye: mdiEye
            }
        }
    }
}
</script>

<style scoped lang="scss">
.source-url {
    position: relative;
}

.unblur-text {
    position: absolute;
    top: 10px;
    right: 20px;
}

.blury-link {
    color: transparent;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.5) !important;
}

.configure-app {
    min-height: 600px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        margin-right: 5px !important;
    }

    &::-webkit-scrollbar-track {
        background: #2A1953 !important;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--v-pink-base) !important;
    }
}
</style>