<template>
	<div class="page-title">
		<v-card>
			<v-card-text class="py-3">
				<h2 class="textColor--text fs--25 fw--700">{{ title }}</h2>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.page-title {
	.v-card {
		.v-card__text {
			font-weight: bold;
			font-size: larger;
			color: #d9d6ee;
		}
	}
}
</style>
