<template>
    <div>
        <v-tabs v-model="tab" slider-color="pink" color="white" background-color="transparent" grow>
            <v-tab class="secondary--text" :class="tab === 0 && 'white--text'">
                <v-icon :color="tab === 0 ? 'white' : 'secondary'" class="mr-1">{{ icons.windows }}</v-icon> Windows Guide
            </v-tab>
            <!-- <v-tab class="secondary--text" :class="tab === 1 && 'white--text'">
                <v-icon :color="tab === 1 ? 'white' : 'secondary'" class="mr-1">{{ icons.mac }}</v-icon> Mac Guide
            </v-tab> -->
        </v-tabs>
        <v-divider class="purple"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <WindowsGuide />
            </v-tab-item>
            <!-- <v-tab-item>
                <MacGuide />
            </v-tab-item> -->
        </v-tabs-items>
    </div>
</template>

<script>
import { mdiMicrosoftWindows, mdiApple } from '@mdi/js'
import { ref } from '@vue/composition-api'
import WindowsGuide from '@/views/streamer/stick-o-vision/WindowsGuide'
import MacGuide from '@/views/streamer/stick-o-vision/MacGuide'

export default {
    components: {
        WindowsGuide,
        MacGuide
    },
    setup() {
        const tab = ref(0);

        const actions = {
            
        }
        return {
            tab,
            actions,
            icons: {
                windows: mdiMicrosoftWindows,
                mac: mdiApple
            }
        }
    }
}
</script>

<style scoped lang="scss">
.source-url {
    position: relative;
}

.unblur-text {
    position: absolute;
    top: 10px;
    right: 20px;
}

.blury-link {
    color: transparent;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.5) !important;
}

.configure-app {
    height: auto;
    max-height: 515px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        background: var(--v-purple-base) !important;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--v-pink-base) !important;
    }
}
</style>