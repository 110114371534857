<template>
    <div class="pb-10">
        <page-title title="Stick-O-Vision: Desktop Overlay"></page-title>

        <v-row class="mt-5">
            <v-col cols="12" md="6">
                <HowItWorks />
            </v-col>
            <v-col cols="12" md="6">
                <v-card min-height="750">
                    <v-card-text class="pa-1">
                        <h3 class="fs--23 fw--400 mb-4 px-5 pt-5">How to install</h3>
                        <ConfigureApp />
                        <!-- <v-stepper class="px-0" flat v-model="step">
                            <v-stepper-header class="px-0">
                                <v-stepper-step :step="1">
                                    <Step :step="step" :current="1" title="Download" subtitle="the app" />
                                </v-stepper-step>
                                <v-divider :class="actions.addLineClass(1)"></v-divider>
                                <v-stepper-step :step="2">
                                    <Step :step="step" :current="2" title="Configure" subtitle="the app" />
                                </v-stepper-step>
                            </v-stepper-header>
                            <v-divider class="purple"></v-divider>
                            <v-stepper-items class="sticker-steps">
                                <v-stepper-content class="pa-3 px-md-4" :step="1">
                                    <DownloadApp />
                                </v-stepper-content>
                                <v-stepper-content class="pa-3" :step="2">
                                    <ConfigureApp v-if="step === 2" />
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>

                        <div class="ma-4 pb-3" :class="step === 2 ? 'text-left' : 'text-right'">
                            <v-btn v-if="step === 1" color="pink" class="px-7" depressed @click="step = 2">Next</v-btn>
                            <v-btn v-if="step === 2" color="pink" outlined class="px-7" depressed @click="step = 1">Back</v-btn>
                        </div> -->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { reactive, onMounted, ref } from '@vue/composition-api';
import PageTitle from '@/components/PageTitle.vue';
import Step from '@/views/streamer/stickers/tts/Step.vue';
import HowItWorks from '@/views/streamer/stick-o-vision/HowItWorks'
import DownloadApp from '@/views/streamer/stick-o-vision/DownloadApp'
import ConfigureApp from '@/views/streamer/stick-o-vision/ConfigureApp'

export default {
    components: {
        PageTitle,
        Step,
        HowItWorks,
        DownloadApp,
        ConfigureApp
    },
    setup() {
        const step = ref(1);

        const actions = {
            addLineClass: function (tab) {
                if (step.value === tab) {
                    return 'step-dash purpleDivider';
                }
                if (step.value > tab) {
                    return 'step-dash pink';
                }
                if (step.value < tab) {
                    return 'step-dash purpleDivider';
                }
            },
        }

        return {
            step,
            actions
        }
    }
}
</script>