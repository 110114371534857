<template>
    <div>
        <p class="fs--13">
            Download the "<span class="blueWaves--text">Stick-O-Vision</span>" applicationfor PC or Mac. If you run a dual
            computer streaming setup, this would be for the computer you play games on.
        </p>

        <div class="d-flex flex-wrap justify-center mt-3 mb-5">
            <v-btn class="text-capitalize ma-1" color="pink" depressed>
                <v-icon class="mr-1">{{ icons.windows }}</v-icon>
                Download for windows</v-btn>
            <v-btn class="text-capitalize ma-1" color="pink" depressed>
                <v-icon class="mr-1">{{ icons.mac }}</v-icon>
                Download for Mac</v-btn>
        </div>

        <div class="text-center mb-5">
            <img src="@/assets/images/Temp-VisualExplainer.png" width="85%" alt="">
        </div>


        <p class="fs--13">
            Once installed run the application and tap NEXT to proceed the Step two when it's open.
        </p>
        <p class="fs--13 blueWaves--text">
            Note: The application will be transparent when it first loads!
        </p>
    </div>
</template>

<script>
import { mdiMicrosoftWindows, mdiApple } from '@mdi/js'

export default {
    setup() {
        return {
            icons: {
                windows: mdiMicrosoftWindows,
                mac: mdiApple
            }
        }
    }
}
</script>