<template>
	<div class="mt-4 px-md-4 configure-app">
		<v-snackbar v-model="copied" :timeout="2000" top color="purple"> Link Copied Successfully. </v-snackbar>
		<span class="fs--14 fw--500">STEP 1</span>
		<p class="fs--13 fw--400 fieldFore--text mt-4">
			Download the <span class="blueWaves--text fw--600">Stick-O-Vision</span> application by clicking the button
			below. If you run a dual computer streaming setup, this would be run on the PC you play games with, not your
			streaming PC.
		</p>

		<v-btn @click="actions.downloadApp()" class="text-capitalize ma-1" color="pink" depressed>
			<v-icon class="mr-1">{{ icons.windows }}</v-icon>
			Download for windows</v-btn
		>

		<v-divider class="my-4"></v-divider>

		<span class="fs--14 fw--500">STEP 2</span>

		<p class="fs--13 fw--400 fieldFore--text mt-4">
			Extract the ZIP files to any location on your computer and run the application file called
			<span class="blueWaves--text fw--600">Stick-O-Vision</span>.
		</p>
		<p class="fs--13 fw--400 fieldFore--text">
			Next, copy your personal <span class="blueWaves--text fw--600">Browser Source URL</span> to your clipboard
			using the <span class="blueWaves--text fw--600">COPY</span> button below:
		</p>
		<v-row no-gutters class="mb-6">
			<v-col cols="8" sm="9" lg="10" xl="10" class="pr-3 source-url">
				<v-text-field disabled background-color="purple" color="fieldFore" dense flat solo hide-details>
					<template #label>
						<div class="d-flex">
							<span :class="blur && 'blury-link'">{{ browserSourceLink }}</span>
						</div>
					</template>
				</v-text-field>
				<span v-if="blur" @click="actions.removeBlur" class="unblur-text cursor-pointer white--text fs--12"
					><v-icon size="18" color="white">{{ icons.eye }}</v-icon> Click here to show the URL</span
				>
			</v-col>
			<v-col cols="4" sm="3" lg="2" xl="2">
				<v-btn depressed color="primary" block @click="actions.copy">Copy</v-btn>
			</v-col>
		</v-row>

		<v-divider class="mb-4"></v-divider>

		<span class="fs--14 fw--500">STEP 3</span>
		<p class="fs--13 fw--400 fieldFore--text mt-4">
			Open the Stick-O-Vision application and paste your
			<span class="blueWaves--text fw--600">Browser Source URL</span> into the field as shown below.
		</p>

		<div class="text-center mb-3">
			<img src="@/assets/images/obs-instructions.png" width="100%" alt="" />
		</div>

		<p class="fs--13 fw--400 fieldFore--text mb-6">
			If you run multiple monitors, ensure you have selected the monitor you want the stickers to appear on in the
			<span class="blueWaves--text fw--600">Select Monitor</span> dropdown.
		</p>

		<v-divider class="mb-4"></v-divider>

		<span class="fs--14 fw--500">STEP 4</span>
		<p class="fs--13 fw--400 fieldFore--text mt-4">
			Head over to OBS and add a new <span class="blueWaves--text fw--600">Game Capture</span> as shown below.
		</p>
		<div class="text-center mb-3">
			<img src="@/assets/images/obs-game-capture.png" width="100%" alt="" />
		</div>
		<p class="fs--13 fw--400 fieldFore--text mt-3">
			This Game Capture layer be set up to capture the window
			<span class="blueWaves--text fw--600">“Stick-O-Vision.exe”</span>.
		</p>

		<p class="fs--13 fw--400 fieldFore--text">
			<span class="blueWaves--text fw--600">IMPORTANT:</span> Ensure you enable
			<span class="blueWaves--text fw--600">“Allow Transparency”</span> to ensure your viewers can see the game
			behind your sticker overlay.
		</p>

		<div class="text-center mb-3">
			<img src="@/assets/images/obs-transparency.png" width="100%" alt="" />
		</div>

		<p class="fs--13 fw--400 fieldFore--text">
			<span class="blueWaves--text fw--600">IMPORTANT:</span> When running the Stick-O-Vision Overlay, audio will
			by default be sent from the application itself, meaning you’ll want to toggle off the visibility of your
			existing OBS Stream Stickers Browser source to avoid hearing double sounds. If you continue to hear double
			sounds after you have done this, make sure “Shutdown source when not visible” is enabled on the existing OBS
			Stream Stickers Browser Source layer.
		</p>

		<p class="fs--13 fw--400 fieldFore--text">
			Ensure that your Stick-O-Vision Game capture is the <span class="blueWaves--text fw--600">TOP</span> most
			layer in your scene.
		</p>

		<p class="fs--13 fw--400 fieldFore--text mb-6">
			Once done, click <span class="blueWaves--text fw--600">OK</span>
		</p>

		<v-divider class="mb-6"></v-divider>

		<span class="fs--14 fw--500">STEP 5</span>
		<p class="fs--13 fw--400 fieldFore--text mt-4">
			Head back to the Stick-O-Vision application and click
			<span class="blueWaves--text fw--600">Enable</span> and let the chaos begin!
		</p>
		<p class="fs--13 fw--400 fieldFore--text">
			<span class="blueWaves--text fw--600">IMPORTANT:</span> Make sure you are playing your games in
			<span class="blueWaves--text fw--600">“full screen window”</span> mode to ensure you can see the
			Stick-O-Vision overlay on top. Running games in full screen mode will cause the game to render on top of the
			stickers.
		</p>
		<p class="fs--13 fw--400 fieldFore--text">
			If at any time you want to toggle the overlay visibility, disable audio, or return to the settings screen,
			you can use the configured hotkeys while the overlay is running. These keys can also be remapped to your
			personal preferences.
		</p>
		<p class="fs--13 fw--400 fieldFore--text">
			If you want to disable Stick-O-Vision and go back to the regular overlay, simply toggle the Stick-O-Vision
			visibility in OBS to OFF and make the original Stream Stickers browser source visible again. Keeping the
			Stick-O-Vision layer invisible in your scene will make it easier to start it up again later.
		</p>
		<p class="fs--13 fw--400 fieldFore--text">That’s it!</p>
	</div>
</template>

<script>
import { mdiMicrosoftWindows, mdiApple, mdiEye } from '@mdi/js';
import { ref } from '@vue/composition-api';
import { getEndpointHost } from '@/utils/config';
import { getUserId } from '@/utils/auth';

export default {
	setup() {
		const browserSourceLink = `https://${getEndpointHost()}:8081/stickers/${getUserId()}/mt`;
		const copied = ref(false);
		const blur = ref(true);

		const actions = {
			downloadApp() {
				window.open('https://bit.ly/Stick-O-Vision-PC', '_blank');
			},
			copy: () => {
				navigator.clipboard.writeText(browserSourceLink).then(() => {
					copied.value = true;
					setTimeout(() => {
						copied.value = false;
					}, 2000);
				});
			},
			removeBlur: () => {
				blur.value = false;
			},
		};
		return {
			blur,
			browserSourceLink,
			copied,
			actions,
			icons: {
				windows: mdiMicrosoftWindows,
				mac: mdiApple,
				eye: mdiEye,
			},
		};
	},
};
</script>

<style scoped lang="scss">
.source-url {
	position: relative;
}

.unblur-text {
	position: absolute;
	top: 10px;
	right: 20px;
}

.blury-link {
	color: transparent;
	text-shadow: 0 0 4px rgba(255, 255, 255, 0.5) !important;
}

.configure-app {
	min-height: 600px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		margin-right: 5px !important;
	}

	&::-webkit-scrollbar-track {
		background: #2a1953 !important;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--v-pink-base) !important;
	}
}
</style>
