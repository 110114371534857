<template>
	<div class="d-flex align-center">
		<div class="dot" :class="actions.addDotClass(current)">
			<div v-if="step === current" class="white-dot"></div>
		</div>
		<strong class="fs--33 fw--700 mx-2" :class="actions.addTextClass(current)">0{{ current }}</strong>
		<div>
			<small class="fs--12 fw--400" :class="actions.addTextClass(current)">{{ title }}</small> <br />
			<small class="fs--12 fw--400" :class="actions.addTextClass(current)">{{ subtitle }}</small>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		step: {
			type: Number,
			default: 1,
		},
		current: {
			type: Number,
			default: 1,
		},
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
	},
	setup(props, context) {
		const actions = {
			addTextClass: function (current) {
				if (props.step === current) {
					return 'offWhite--text';
				}
				if (props.step > current) {
					return 'pink--text';
				}
				if (props.step < current) {
					return 'boldPurple--text';
				}
			},

			addDotClass: function (current) {
				if (props.step === current) {
					return 'dot pink';
				}
				if (props.step > current) {
					return 'dot pink';
				}
				if (props.step < current) {
					return 'dot circle';
				}
			},
		};

		return {
			actions,
		};
	},
};
</script>

<style lang="scss" scoped>
.dot {
	width: 23px;
	height: 23px;
	border-radius: 50%;
}

.circle {
	border: 3px solid var(--v-boldPurple-base);
}

.white-dot {
	width: 12px;
	height: 12px;
	background: white;
	margin-left: 5px;
	margin-top: 5px;
	border-radius: 50%;
}
</style>
